/*Global Initialization*/
body, html {
  height: 100vh;
  width: 100vw;
  /*font-family: Interface,sans-serif;*/
  font-family: Source Sans Pro,sans-serif;
  font-size: 14px;
  background: #f1f2f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
a, a:hover {
  text-decoration: none;
}
.hidden {
  display: none;
}
.col-width-150 {
  min-width: 150px;
}
.col-width-50 {
  max-width: 50px;
}
.col-width-90 {
  max-width: 80px;
}
th, td {
  text-align: center;
}
.btn {
  border-radius: 3px;
}
.btn:focus, .btn:hover {
  box-shadow: none;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default.active, .btn-default:active, .btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.text-muted {
  color: #798e9b !important;
}
.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
#loading {
  position: fixed;
  right: 50px;
  bottom: 50px;
  display: none;
}
/*Flex*/
.row-gap-10 > :not(:last-child) {
  margin-right: 10px;
}
.row-gap-7>:not(:last-child) {
  margin-right: 7px;
}
.row-gap-4>:not(:last-child) {
    margin-right: 4px;
}
.flex {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.flex-column {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-column, .flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
}
.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
/*Tooltip color change*/
.tooltip-inner {
  color: white;
  background-color: #5496f8;
  font-size: 12px;
  padding: 3px 40px;
}
.tooltip.bs-tether-element-attached-left .tooltip-inner::before, .tooltip.tooltip-right .tooltip-inner::before {
  border-right-color: #5496f8 !important;
}
/*Main menu*/
.navbar {
  background: #353f45;
  margin-bottom: 0;
  border: 0;
  /*font-size: 14px;*/
  width: 100%;
  font-weight: 300;
  top: 0;
  padding: 0 20px;
}
.navbar-brand {
  padding: 7px 20px 7px 0;
}
.navbar-brand .fa {
  margin-right: 5px;
}
.navbar-brand span {
  font-size: 13px;
  width: 110px;
  height: 21px;
  vertical-align: text-top;
}
.navbar-nav .nav-item a {
  color: #f0f5f9 !important;
  font-weight: 500 !important;
  padding: 15px !important;
  /*font-size: 14px;*/
}
.navbar-inverse .navbar-nav .active>.nav-link {
  color: #80b1fa !important;
}
/*Filter bar*/
.container-filter {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: #fff;
  border-bottom: 1px solid #c9d6de;
  padding: 5px 30px;
  width: 100%;
  position: relative;
  justify-content: space-between;
}
.container-filter .filter-item:not(:last-child) {
  margin-right: 10px;
}
.container-filter .filter-item {
  height: 31px;
}
.container-filter .btn {
  padding: 4px 10px;
  background: #efefef;
  color: #52616a;
  border: 1px solid #c9d6de;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  cursor: pointer;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: text-top;
}
.container-filter #btnUserList, .container-filter #btnUserMap {
  padding-top: 7px;
}
.container-filter #btnUserList.active:hover, .container-filter #btnUserMap.active:hover {
  color: white;
}
.container-filter .btn .fa {
  margin-right: 4px;
}
.container-filter .btn .fa, .container-filter .btn span {
  /*font-size: 14px;*/
}
.container-filter .btn.active {
  background: #5496f8;
  color: #fff;
  cursor: default;
  border-color: #287bf6;
}
.container-filter .dropdown-toggle::after {
  display: none;
}
.container-filter .btn-plain{
  background-color: #fff;
}
.container-filter .btn-plain:hover,
.container-filter .btn-plain:focus {
  background-color: #e6e6e6;
  border-color: #adadad;
}
.container-filter .show>.btn-plain {
  background-color: #e6e6e6;
  border-color: #adadad;
  color: #333 !important;
}

.container-filter .btn-plain .fa {
  font-size: 12px;
  margin-right: 0;
}
.container-filter .btn-plain span {
  font-size: 13px;
}
.container-filter .dropdown-menu {
  min-width: 160px;
  padding: 5px 0;
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  background-clip: padding-box;
}
.container-filter .sort-header {
  background: #ededed;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.container-filter .sort-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 11px;
}
.container-filter .sort-items .sort-item {
  padding: 4px 6px;
  cursor: pointer;
}
.container-filter .sort-items .sort-item .fa-sort-amount-desc {
  padding-top: 3px;
}
.container-filter .dropdown-menu .active {
  background: #52616a;
  color: #f3f3f3;
}
.container-filter .sort-items .sort-item:hover {
    background: #ededed;
}
.container-filter .dropdown-menu .active .fa-active {
  color: #5496f8;
}
.container-filter .dropdown-menu > li > a {
  display: block;
  /*font-size: 14px;*/
  padding: 3px 20px;
  text-decoration: none;
  color: #333;
}
.container-filter .dropdown-menu>li>a:hover {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
/*Filter labels*/
.filter {
  background: #798e9b;
  cursor: pointer;
  border-radius: 4px;
  padding: 3px 5px;
  color: #fff;
  height: 27px;
  margin-top: 2px;
  display: inline;
}
.filter:not(:last-of-type) {
  margin-right: 10px;
}
.container-filter #formSearchUser input[type="text"] {
  /*font-size: 14px;*/
  padding: 6px 12px;
  margin-right: 0 !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: white;
}
.container-filter #formSearchUser input[type="text"]:focus {
  border-right-color: #5cb3fd;
}
.container-filter #formSearchUser button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  /*font-size: 14px;*/
  padding: 6px 12px;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.container-filter #formSearchUser button:hover, #formSearchUser button:focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
#filter-right {
  position: relative;
  float: right;
}
/*DateRangePicker*/
#daterange {
  font-size: 14px;
  color: #333;
}
#daterange .fa {
  font-size: 14px;
}
#daterange .fa.fa-sort-desc {
  vertical-align: text-top;
}
.ranges li {
  font-size: 13px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  color: #52616a;
  padding: 3px 12px;
  margin-bottom: 8px;
  cursor: pointer;
}
.daterangepicker td.active, .daterangepicker td.active:hover, .ranges li:hover {
    background: #52616a;
}
.ranges li:hover {
    border-color: #52616a;
}
.ranges li.active {
    background: #52616a;
    border-color: #52616a;
}

.daterangepicker .input-mini {
  background-color: #fff;
  color: #555;
  height: 30px;
  line-height: 30px;
  display: block;
  vertical-align: middle;
  margin: 0 0 5px 0;
  padding: 0 6px 0 28px;
  width: 100%;
  font-size: 14px;
}
.table-condensed>tbody>tr>td, .table-condensed>tbody>tr>th, .table-condensed>tfoot>tr>td, .table-condensed>tfoot>tr>th, .table-condensed>thead>tr>td, .table-condensed>thead>tr>th {
  padding: 5px;
}
/*User Map Body*/
#user-map, .user-table, .action-list {
  height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  position: relative;
  flex: 1;
  justify-content: space-between;
}
#user-map #sidebar {
  flex-basis: 400px;
  height: 100%;
  overflow: auto;
  border-right: 1px solid #c9d6de;
}
#user-map .sidebar-container {
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#user-map .sidebar-container .chart {
  position: relative;
}
#user-map .sidebar-container .chart .total-count {
  position: absolute;
  left: 50%;
  top: 44%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  font-weight: 500;
}
#user-map .sidebar-container .chart .total-text {
  position: absolute;
  left: 50%;
  top: 58%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #52616a;
  text-transform: uppercase;
}
.label-container {
  min-width: 170px;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.graph-label {
  transition: color .2s;
  cursor: pointer;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
}
.graph-label:hover, .graph-label.active {
  background: #dcdcdc;
}
.box {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
.user-map-info, .action-map-info {
  padding: 20px 15px;
}
.card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}
.card-container .card {
    margin-bottom: -1px;
}
.card {
  color: #52616a;
  background: #fff;
  border: 1px solid #d5dfe5;
  position: relative;
  border-radius: 0;
}
.clickable-card {
  cursor: pointer;
  z-index: 1;
}
.clickable-card:hover {
  background: #fafafa;
}
.card-content-bottom, .card-content-mid {
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
}
.card-content-top {
  padding-top: 10px;
}
.profile-img {
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
}
.card .text-muted {
  font-size: 11px;
  font-weight: 300;
}
.card-container .card-action {
  height: 30px;
  background: #5496f8;
  color: #fff;
  border: 1px solid #c9d6de;
  position: relative;
  margin: 0 10px 3px;
  display: none;
  padding: 5px 20px 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-transform: uppercase;
}
.card-container .card-action:hover {
  background: #3c87f7;
  font-weight: 500;
}
/*User List Page*/
.user-table, .action-list {
  padding: 25px;
  height: 100%;
  overflow: auto;
}
.user-table .user-table-container, .action-list .action-list-container {
  width: 100%;
}
.user-table #user-data_length,
.user-table #user-data_filter,
.user-table #user-data_info,
.user-table #user-data_paginate,
.action-table #action-data_length,
.action-table #action-data_filter,
.action-table #action-data_info {
  display: none;
}
.action-table #action-data_paginate ul li {
  margin-left: 6px;
  margin-right: 6px;
  border: 1px solid #d9d9d9;
  width: 28px;
  height: 28px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 3px;
  cursor: pointer;
  color: #666;
}
.action-table #action-data_paginate ul li a {
  line-height: 18px;
  font-size: 12px;
  padding: 3px 10px;
}
#user-data, #action-data {
  border: 1px solid #e9e9e9;
}
table.dataTable thead>tr>th.sorting_asc, table.dataTable thead>tr>th.sorting_desc, table.dataTable thead>tr>th.sorting, table.dataTable thead>tr>td.sorting_asc, table.dataTable thead>tr>td.sorting_desc, table.dataTable thead>tr>td.sorting {
  padding-right: 25px;
}
#user-data > thead, #action-data > thead {
  background-color: #f7f7f7;
}
#user-data thead th {
  border: none;
  text-align: right;
}
#action-data thead th {
  border: none;
}
#user-data thead th:first-of-type {
  text-align: center;
}
#user-data, #action-data {
  color: #666;
  font-size: 12px;
}
#user-data tbody, #action-data tbody {
  background-color: #fff;
}
#user-data tbody tr:hover, #action-data tbody tr:hover {
  background: #fcfcfc;
}
#user-data tbody td {
  text-align: right;
}
#user-data tbody td:first-of-type {
  text-align: left;
}
#user-data tbody td span, #action-data tbody td span {
  padding-right: 10px;
}
#user-data tbody tr.tr-average td {
  text-align: right;
  padding-right: 16px;
}
#user-data tbody tr.tr-average td:first-of-type {
  text-align: left;
}
#user-data .profile-img, #user-data .user-name {
  display: inline-block;
}
#user-data .user-name {
  vertical-align: top;
  height: 30px;
}
#user-data td span, #action-data td span {
  line-height: 30px ;
}
#user-data .profile-img {
  margin-right: 10px;
}
#user-data td {
  padding: 9px 6px 9px 6px;
}

/*Action page*/
.container-filter .action li {
  color: #333;
  font-size: 13px;
  white-space: nowrap;
}
.container-filter .action li a {
  padding: 3px 20px;
}
.container-filter .action .dropdown-header {
  padding: 3px 20px;
  background: #ededed;
  color: #888;
}
.status {
  font-weight: 700;
  font-size: 16px;
}
.action-map-info {
  padding-top: 0;
}
.action-map-info .card-content-top {
  padding: 10px 20px 0;
}
.column-gap-10>:not(:last-child) {
    margin-bottom: 10px;
}
.action-stats {
  font-size: 10px;
}
.action-map-info .card .text-muted {
  font-size: 10px;
  font-weight: 300;
}
.action-map-info .card-content-bottom, .card-content-mid {
  padding: 0 20px 10px;
}
/*action list*/
.action-table {
  margin-top: 20px;
  margin-bottom: 40px;
}
.action-table th, .action-table td {
  text-align: left;
  padding: 9px 6px 9px 20px;
}
.page-item.active .page-link {
  background-color: #108ee9;
  border-color: #108ee9;
}
.text-warning {
  color: #e6413e!important;
}
.text-ontime {
  color: #58ae5b;
}
/*user single*/
.live-user {
  padding: 20px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.timeline-user {
  padding: 20px 0;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex: 1;
}
.timeline-container {
  width: 100%;
  margin-bottom: 50px;
}
.sticky {
  position: absolute;
  background: #fff;
  top: 0;
  width: 100%;
  left: 0;
  max-width: 400px;
  padding: 10px 40px;
  z-index: 150;
  border-bottom: 1px solid #c9d6de;
}
.popup-close {
  color: #52616a;
}
.timeline-container .sticky {
  z-index: 150;
}
.user-card {
  width: 100%;
  margin-bottom: 20px;
}
.card-content-mid {
  padding-top: 10px;
}
.user-info-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
}
.user-name {
  font-size: 15px;
}
.user-status-display {
  font-size: 13px;
  font-weight: 700;
}
.user-status {
  font-size: 11px;
}
.text-red {
    color: #e6413e;
}
.placeline {
  margin: 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.segment {
  border-radius: 4px;
}
.active-segment:not(:last-of-type) {
  background: hsla(0,0%,100%,.75);
}
.time-container {
  width: 96px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: right;
  padding-right: 20px;
  padding-bottom: 16px;
  z-index: 5;
}
.time-container .text-muted {
  color: #a9bac4;
  font-size: 12px;
}
.time-container .timestamp {
  font-size: 14px;
  color: #52616a;
}
.time-container .text-muted {
  color: #a9bac4;
  font-size: 12px;
}
.pipe {
  width: 20px;
  min-width: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
}
.pipe .solid {
  border-right: 3px solid #798e9b;
}
.pipe .bar {
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.pipe .big {
  outline: 2px solid;
}
.stop-border {
  border-color: #fb4!important;
  outline-color: #fb4!important;
}
.timeline-detail {
  padding-bottom: 22px;
  position: relative;
  padding-left: 16px;
  min-height: 66px;
}
.activity-dot {
  border: 1px solid #798e9b;
  font-size: 10px;
}
.a-dot, .segment-dot {
  background: #fff;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 2;
}
.segment-dot {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: -1px;
  left: -23px;
}
.activity-dot-ended {
  background: #798e9b !important;
  color: #fff;
}
.trip-border {
  border-color: #5496f8!important;
  outline-color: #5496f8!important;
}
.a-dot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 2px solid #798e9b;
  top: -5px;
  left: -14px;
  background-color: #fff;
}
.a-dot, .segment-dot {
  background: #fff;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.auto {
  margin: auto;
}
.action-card {
  background: #fff;
  padding: 3px 11px;
  border: 1px solid #c9d6de;
  font-size: 11px;
  cursor: pointer;
  color: #52616a;
  margin-left: -127px;
  padding-left: 134px;
  margin-top: -9px;
  min-height: 50px;
  box-shadow: 1px 2px 2px 0 rgba(0,0,0,.07);
}
.action-card-active, .action-card:hover {
  border: 1px solid #798e9b;
}
.title {
  font-size: 13px;
  font-weight: 700;
}
.activity-card {
  padding: 6px 10px;
  color: #52616a;
  transition: font-size .4s;
  font-size: 13px;
  position: relative;
  cursor: pointer;
}
.activity-card-selected, .activity-card-selected:hover {
  background: #fff;
}
.stop-color {
  color: #ffb025;
}
.trip-color {
  color: #5496f8;
}
.activity-stats {
  font-size: 12px;
  font-weight: 700;
}
.activity-card .close-card {
  position: absolute;
  top: -3px;
  right: -3px;
  color: #52616a;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: none;
  z-index: 150;
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex; */
}
.activity-card .text-muted {
  display: none;
}
.table {
  margin: 7px 0;
  font-size: 11px;
}
.table-bordered {
  border: 1px solid #ddd;
}
.no-info-border {
  border-color: #a9bac4!important;
  outline-color: #a9bac4!important;
}
.no-info-color {
  color: #a9bac4;
}
.pipe .line, .pipe .line-border {
  border-right: 3px dotted #798e9b;
}
.pulse {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  -webkit-animation: pulse 3s ease-out;
  animation: pulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  z-index: 10;
  opacity: 1;
  margin: auto;
}
@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}
.trip-bg {
  background: #5496f8;
}
.stop-bg {
  background: #fb4;
}
.date {
  background-color: #fff;
  border: 1px solid #c9d6de;
}
.date .action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 7px 14px;
  cursor: pointer;
  height: 100%;
}
.date .action-left {
  border-right: 1px solid #c9d6de;
}
.date .action-right {
  border-left: 1px solid #c9d6de;
}
.date .action .fa {
  margin: auto;
}
.date .date-text {
  padding: 4px 5px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 134px;
  cursor: pointer;
  /* border: none;
  color: #333; */
}
/* .date .date-text:focus {
  outline: none;
} */
.date .disabled {
  color: #c9d6de;
  cursor: not-allowed;
}
/*Actions*/
.live-actions {
  padding: 20px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.action-page {
  padding: 20px 0;
}
.action-detail-header, .lookup-id {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 9px;
}
.action-detail-header {
  font-size: 21px;
}
.lookup-id {
  text-decoration: underline;
  font-size: 16px;
}
.user, .users-card {
  margin-bottom: 20px;
}
.users-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.action-detail-container {
  padding-bottom: 3px;
}
.action-detail-container .action-icon {
  width: 30px;
  height: 30px;
}
.action-detail-container .action-display-status {
    font-size: 13px;
    font-weight: 700;
}
.action-detail-container a {
    color: #5496f8;
}
.expanded .add-content {
  font-size: 12px;
}
.expand {
  font-size: 6px;
  cursor: pointer;
  opacity: 0;
}
.hide {
  display: none;
}
.action-info:hover .expand {
  opacity: 1;
}
.action-detail-container .action-display-status {
  font-size: 13px;
  font-weight: 700;
}
